import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PageBanner from "../components/page-banner"
import ProductPageWithGrid from "../components/product-page-with-grid"
import HomeContact from "../components/home-contact"
import Router from "../domain/router"

const Page = ({ data }) => {
  const mainPage = data.wordpressMainPage
  const posts = data.posts.nodes
  const term = data.term
  const parent_link = Router.productsPath()
  const title = term.name

  return (
    <Layout>
      <SEO term={term} />
      <PageBanner title={title} parentPost={mainPage} parent_link={parent_link} />
      <ProductPageWithGrid term={term} posts={posts} />
      <HomeContact />
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query($termId: Int!, $skip: Int!, $limit: Int!) {
    posts: allWordpressProduto(
      limit: $limit,
      skip: $skip,
      sort: {order: ASC, fields: menu_order},
      filter: {categoria_produtos_ids: {in: [$termId]}, status: {eq: "publish"}}
    ) {
      nodes {
        ...ProdutoItem
      }
    }

    term: wordpressCategoriaProduto(term_id: {eq: $termId}) {
      term_id
      taxonomy
      slug
      name
      count
      yoast_title
      yoast_json_ld
      yoast_meta {
        content
        name
        property
      }
    }

    wordpressMainPage(name: {eq: "produtos"}) {
      ...MainPageFull
    }
  }
`
