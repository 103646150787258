import React from "react"
import ProductSearch from "./product-search"
import ProductTabs from "./product-tabs"
import ProductGrid from "./product-grid"

const ProductPageWithGrid = ({ term, posts }) => {
  return (
    <section className="product-page-component">
      <div className="container is-padding-top-10">
        <div className="is-margin-bottom-8">
          <ProductSearch />
        </div>
        <div className="is-margin-bottom-10">
          <ProductTabs active={term.slug} />
        </div>
        <div className="is-margin-bottom-10">
          <ProductGrid posts={posts} />
        </div>
      </div>
    </section>
  )
}

export default ProductPageWithGrid
