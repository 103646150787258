import React from "react"
import { get } from "lodash"
import { Link } from "gatsby"
import { LazyLoadImage } from "react-lazy-load-image-component"
import Router from "../domain/router"
import Thumbnail from "../domain/thumbnail"

const ProductGrid = ({ posts }) => {
  return (
    <div className="product-grid-component is-padding-horizontal-4-touch">
      <div className="columns is-multiline">
        {posts.map(post => {
          const primary_category = 'product-grid category-' + get(post, "primary_categoria_produtos.slug")
          const productPath = Router.productItemPath(post)

          return (
            <div key={post.post_id} className="column product-item is-one-fifth-desktop is-6-tablet is-12-mobile">
              <Link to={productPath} className={`product-thumb has-text-centered ${primary_category}`}>
                <LazyLoadImage src={Thumbnail.productGridImage(post)} alt={post.title} />
              </Link>
              <Link to={productPath} className={`product-title has-text-black ${primary_category}`}>
                {post.title}
              </Link>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default ProductGrid
